
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('financialAid.vill_sub_demand_collection') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      :label="$t('globalTrans.fiscal_year')"
                      label-for="fiscal_year_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalList"
                          id="fiscal_year_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      :label="$t('dae_config.season_name')"
                      label-for="season_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.season_id"
                          :options="seasonSetupList"
                          id="season_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
               <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      :label="$t('financialAid.circular')"
                      label-for="circular_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.circular_id"
                          :options="circularList"
                          id="circular_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      :label="$t('financialAid.project')"
                      label-for="project_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.project_id"
                          :options="projectNameList"
                          id="project_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('financialAid.mobile_no')"
                    label-for="nid"
                    >
                    <b-form-input
                        id="mobile_no"
                        v-model="search.mobile_no"
                        placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('financialAid.vill_sub_demand_collection_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(mobile_no)="data">
                                          <template v-if="data.item.mobile_no !== undefined">
                                            {{ data.item.mobile_no | mobileNumber }}
                                          </template>
                                          <template v-else>
                                            {{ ' ' }}
                                          </template>
                                        </template>
                                        <template v-slot:cell(nid_no)="data">
                                            {{ $n(data.item.nid_no, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                          {{ getStatus(data.item.status) }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                          <slot v-if="data.item.status === 1">
                                            <b-button v-if="data.item.status === 1" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" title="Edit"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                            <b-button class="mr-1" variant=" iq-bg-success" size="sm" @click="receive(data.item)" title="Receive"><i class="fa fa-check m-0"></i></b-button>
                                          </slot>
                                            <b-button title="View" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
         <b-modal id="modal-5" size="lg" :title="$t('financialAid.vill_sub_demand_collection_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="dtlsItemId"></Details>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import Details from './Details'
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { demandCollectionList, demandCollectionReceive } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        season_id: 0,
        circular_id: 0,
        project_id: 0,
        mobile_no: '',
        status: 1 // demand collection pending
      },
      rows: [],
      item: '',
      itemId: 0,
      dtlsItemId: ''
    }
  },
  computed: {
    circularList: function () {
      const circularData = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.circular_type === 4)
      return circularData.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text_en }
          }
      })
    },
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    seasonSetupList: function () {
      return this.$store.state.incentiveGrant.commonObj.seasonList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn, text_bn: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en, text_en: item.text_en }
        }
      })
    },
    fiscalList: function () {
      const fiscalyearData = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      return fiscalyearData.map(item => {
          if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
          } else {
          return { value: item.value, text: item.text_en }
          }
      })
    },
    projectNameList: function () {
      const projectNameData = this.$store.state.incentiveGrant.commonObj.projectList.filter(item => item.status === 1)
      return projectNameData.map(item => {
          if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
          } else {
          return { value: item.value, text: item.text_en }
          }
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('financialAid.vill_sub_demand_collection') + ' ' + this.$t('globalTrans.entry') : this.$t('financialAid.vill_sub_demand_collection') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('financialAid.fiscal_year'), class: 'text-center' },
          { label: this.$t('financialAid.season'), class: 'text-center' },
          { label: this.$t('financialAid.circular'), class: 'text-center' },
          { label: this.$t('financialAid.project'), class: 'text-center' },
          { label: this.$t('dae_subsidy.farmer_name'), class: 'text-left' },
          { label: this.$t('financialAid.mobile_no'), class: 'text-center' },
          { label: this.$t('financialAid.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'season_bn' },
          { key: 'circular_bn' },
          { key: 'project_bn' },
          { key: 'name_bn' },
          { key: 'mobile_no' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'seasson' },
          { key: 'circular' },
          { key: 'project' },
          { key: 'name' },
          { key: 'mobile_no' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
      this.dtlsItemId = item.id
    },
    async loadData () {
      const params = Object.assign({}, this.search, { upazilla_id: this.authUser.office_detail.upazilla_id, page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const response = await RestApi.getData(incentiveGrantServiceBaseUrl, demandCollectionList, params)
      if (response.success) {
        this.$store.dispatch('setList', this.getRelationalData(response.data.data))
        this.paginationData(response.data)
      } else {
        this.$store.dispatch('setList', [])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList
        const projectNameList = this.$store.state.incentiveGrant.commonObj.projectList
        const circularList = this.$store.state.incentiveGrant.commonObj.circularList
        const listData = data.map(item => {
        const fiscalObject = fiscalYearList.find(fiscal => fiscal.value === item.fiscal_year_id)
        const seasonObject = this.seasonSetupList.find(season => season.value === item.season_id)
        const projectNameObject = projectNameList.find(projectName => projectName.value === item.project_id)
        const circularObject = circularList.find(circularSingle => circularSingle.value === item.circular_id)
        const farmerObject = item.farmer_data
        const fiscalyearData = {
          fiscal_year: typeof fiscalObject !== 'undefined' ? fiscalObject.text_en : '',
          fiscal_year_bn: typeof fiscalObject !== 'undefined' ? fiscalObject.text_bn : ''
          }
        const seasonData = {
          seasson: typeof seasonObject !== 'undefined' ? seasonObject.text_en : '',
          season_bn: typeof seasonObject !== 'undefined' ? seasonObject.text_bn : ''
          }
        const projectNameData = {
          project: typeof projectNameObject !== 'undefined' ? projectNameObject.text_en : '',
          project_bn: typeof projectNameObject !== 'undefined' ? projectNameObject.text_bn : ''
          }
        const circularNameData = {
          circular: typeof circularObject !== 'undefined' ? circularObject.text_en : '',
          circular_bn: typeof circularObject !== 'undefined' ? circularObject.text_bn : ''
          }
        const farmerData = {
          name: typeof farmerObject !== 'undefined' ? farmerObject.name : '',
          name_bn: typeof farmerObject !== 'undefined' ? farmerObject.name_bn : '',
          mobile_no: typeof farmerObject !== 'undefined' ? farmerObject.mobile_no : '',
          nid_no: typeof farmerObject !== 'undefined' ? farmerObject.nid_no : ''
          }
        return Object.assign({}, item, fiscalyearData, seasonData, circularNameData, projectNameData, farmerData)
      })
      return listData
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('budget.pending')
      } else if (status === 2) {
        return this.$t('financialAid.submit')
      } else if (status === 3) {
        return this.$t('budget.forward')
      } else if (status === 4) {
        return this.$t('budget.approve')
      } else if (status === 5) {
        return this.$t('globalTrans.reject')
      } else if (status === 6) {
        return this.$t('financialAid.distributed')
      }
    },
    changeStatus (baseUrl, uri, item, destination = null, dropdownName = null) {
      Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
        if (response.success) {
          /** The following line commented as list is reloaded */
          // Store.dispatch('toggleStatus', item)
          if (destination !== null && dropdownName !== null) {
            Store.dispatch('toggleDropdownItemStatus', { itemId: item.id, destination: destination, dropdownName: dropdownName })
          }
          window.vm.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
        } else {
          window.vm.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
        Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      })
    },
    receive (item) {
      window.vm.$swal({
        title: window.vm.$t('financialAid.receiveMsg'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeStatus(incentiveGrantServiceBaseUrl, demandCollectionReceive, item, 'incentive_grant')
        }
      })
    }
  }
}
</script>
