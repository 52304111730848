<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="loadingState">
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table-simple striped bordered small>
                              <b-tr>
                                <b-th>{{ $t('financialAid.fiscal_year') }}</b-th>
                                <b-td>{{ (this.$i18n.locale=='bn')? demmandDetailsData.fiscal_year_bn : demmandDetailsData.fiscal_year }}</b-td>
                                <b-th>{{ $t('financialAid.season') }}</b-th>
                                <b-td>{{ (this.$i18n.locale=='bn')? demmandDetailsData.season_bn : demmandDetailsData.seasson }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-th>{{ $t('common_config.farmer_nid_no') }}</b-th>
                                <b-td>{{  $n(demmandDetailsData.farmer_data.nid_no, { useGrouping: false } )}}</b-td>
                                <b-th>{{ $t('dae_subsidy.farmer_name') }}</b-th>
                                <b-td>{{ (this.$i18n.locale=='bn') ? demmandDetailsData.farmer_data.name_bn : demmandDetailsData.farmer_data.name }}</b-td>
                              </b-tr>
                              <tr>
                                <b-th>{{ $t('common_config.farmer_father_name') }}</b-th>
                                <b-td>{{ (this.$i18n.locale=='bn')? demmandDetailsData.farmer_data.father_name_bn : demmandDetailsData.farmer_data.father_name }}</b-td>
                                <b-th>{{ $t('common_config.farmer_mother_name') }}</b-th>
                                <b-td>{{ (this.$i18n.locale=='bn')? demmandDetailsData.farmer_data.mother_name_bn : demmandDetailsData.farmer_data.mother_name }}</b-td>
                              </tr>
                              <b-tr>
                                <b-th>{{ $t('common_config.farmer_date_of_birth') }}</b-th>
                                <b-td>{{ demmandDetailsData.farmer_data.date_of_birth | dateFormat }}</b-td>
                                <b-th>{{ $t('common_config.farmer_gender') }}</b-th>
                                <b-td>{{ demmandDetailsData.farmer_data.gender_id === 1 ? 'Male' : 'Female' }}</b-td>
                              </b-tr>
                              <tr>
                                <b-th>{{ $t('common_config.farmer_blood_group') }}</b-th>
                                <b-td>{{ demmandDetailsData.farmer_data != null ? demmandDetailsData.bloodName : '' }}</b-td>
                                <b-th>{{ $t('financialAid.mobile_no') }}</b-th>
                                <b-td>{{ demmandDetailsData.mobile_no | mobileNumber }}</b-td>
                              </tr>
                          </b-table-simple>
                          <b-row>
                            <b-col lg="12" sm="12" class="text-center mb-4">
                              <h5 class='complain-title'>{{ $t('financialAid.frmr_pre') }}</h5>
                            </b-col>
                          </b-row>
                          <b-table-simple striped bordered small>
                            <b-tr>
                              <b-th>{{ $t('globalTrans.division') }}</b-th>
                              <b-td>{{ localeLang === 'bn' ? address.division_name_bn : address.division_name }}</b-td>
                              <b-th>{{ $t('globalTrans.district') }}</b-th>
                              <b-td>{{ localeLang === 'bn' ? address.district_name_bn : address.district_name }}</b-td>
                              <b-th>{{ $t('globalTrans.upazila') }}</b-th>
                              <b-td>{{ localeLang === 'bn' ? address.upazilla_name_bn : address.upazilla_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('globalTrans.union') }}</b-th>
                              <b-td>{{ localeLang === 'bn' ? address.union_name_bn : address.union_name }}</b-td>
                              <b-th>{{ $t('globalTrans.ward_no') }}</b-th>
                              <b-td>{{ localeLang === 'bn' ? address.ward_name_bn : address.ward_name }}</b-td>
                              <b-th>{{ $t('globalTrans.village') }}</b-th>
                              <b-td>{{ localeLang === 'bn' ? address.village_bn : address.village_en }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('financialAid.block') }}</b-th>
                              <b-td>{{ localeLang === 'bn' ? address.block_bn : address.block_en }}</b-td>
                            </b-tr>
                          </b-table-simple>
                           <b-row>
                              <b-col lg="12" sm="12" class="text-center mb-4">
                                  <h5 class='complain-title'>{{ $t('financialAid.frmr_per') }}</h5>
                              </b-col>
                          </b-row>
                          <b-table-simple striped bordered small>
                            <b-tr>
                              <b-th>{{ $t('globalTrans.division') }}</b-th>
                              <b-td>{{ localeLang === 'bn' ? address.per_division_name_bn : address.per_division_name }}</b-td>
                              <b-th>{{ $t('globalTrans.district') }}</b-th>
                              <b-td>{{ localeLang === 'bn' ? address.per_district_name_bn : address.per_district_name }}</b-td>
                              <b-th>{{ $t('globalTrans.upazila') }}</b-th>
                              <b-td>{{ localeLang === 'bn' ? address.per_upazilla_name_bn : address.per_upazilla_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('globalTrans.union') }}</b-th>
                              <b-td>{{ localeLang === 'bn' ? address.per_union_name_bn : address.per_union_name }}</b-td>
                              <b-th>{{ $t('globalTrans.ward_no') }}</b-th>
                              <b-td>{{ localeLang === 'bn' ? address.per_ward_name_bn : address.per_ward_name }}</b-td>
                              <b-th>{{ $t('globalTrans.village') }}</b-th>
                              <b-td>{{ localeLang === 'bn' ? address.per_village_bn : address.per_village_en }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('financialAid.block') }}</b-th>
                              <b-td>{{ localeLang === 'bn' ? address.per_block_bn : address.per_block_en }}</b-td>
                            </b-tr>
                          </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
              </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>

export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.demmandDetailsData = this.getDetailsData()
    this.address = this.getFarmerInformation(this.demmandDetailsData)
    // finding blood group
    const bloodGroupID = this.demmandDetailsData.farmer_data.blood_group_id
    const bloodObject = this.bloodGroupList.find(item => item.value === parseInt(bloodGroupID))
    this.demmandDetailsData.bloodName = bloodObject ? bloodObject.text : ''
  },
  data () {
    return {
      demmandDetailsData: {},
      address: {},
      loadingState: false
    }
  },
  computed: {
    localeLang: function () {
      return this.$i18n.locale
    },
    bloodGroupList: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'O-' : 'ও−' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'O+' : 'ও+' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'A-' : 'এ−' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'A+' : 'এ+' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'B-' : 'বি−' },
        { value: 6, text: this.$i18n.locale === 'en' ? 'B+' : 'বি+' },
        { value: 7, text: this.$i18n.locale === 'en' ? 'AB-' : 'এবি−' },
        { value: 8, text: this.$i18n.locale === 'en' ? 'AB+' : 'এবি+' }
      ]
      return list
    }
  },
  methods: {
    getDetailsData () {
      return JSON.parse(JSON.stringify(this.item))
    },
    getFarmerInformation (data) {
      const villageObj = { village_en: data.farmer_data.address_info.village_en, village_bn: data.farmer_data.address_info.village_bn }
      const blockObj = { block_en: data.farmer_data.address_info.block_en, block_bn: data.farmer_data.address_info.block_bn }

      const perVillageObj = { per_village_en: data.farmer_data.address_info.per_village_en, per_village_bn: data.farmer_data.address_info.per_village_bn }
      const perBlockObj = { per_block_en: data.farmer_data.address_info.per_block_en, per_block_bn: data.farmer_data.address_info.per_block_bn }

      const division = this.$store.state.commonObj.divisionList.find(div => div.value === data.farmer_data.address_info.division_id)
      const district = this.$store.state.commonObj.districtList.find(district => district.value === data.farmer_data.address_info.district_id)
      const upazilla = this.$store.state.commonObj.upazilaList.find(upz => upz.value === data.farmer_data.address_info.upazilla_id)
      const union = this.$store.state.commonObj.unionList.find(union => union.value === data.farmer_data.address_info.union_id)
      const ward = this.$store.state.commonObj.wardList.find(ward => ward.value === data.farmer_data.address_info.ward_id)

      const perdivision = this.$store.state.commonObj.divisionList.find(div => div.value === data.farmer_data.address_info.per_division_id)
      const perdistrict = this.$store.state.commonObj.districtList.find(district => district.value === data.farmer_data.address_info.per_district_id)
      const perupazilla = this.$store.state.commonObj.upazilaList.find(upz => upz.value === data.farmer_data.address_info.per_upazilla_id)
      const perunion = this.$store.state.commonObj.unionList.find(union => union.value === data.farmer_data.address_info.per_union_id)
      const perward = this.$store.state.commonObj.wardList.find(ward => ward.value === data.farmer_data.address_info.per_ward_id)

      const divisionObj = { division_name: division !== undefined ? division.text_en : '', division_name_bn: division !== undefined ? division.text_bn : '' }
      const districtObj = { district_name: district !== undefined ? district.text_en : '', district_name_bn: district !== undefined ? district.text_bn : '' }
      const upazillaObj = { upazilla_name: upazilla !== undefined ? upazilla.text_en : '', upazilla_name_bn: upazilla !== undefined ? upazilla.text_bn : '' }
      const unionObj = { union_name: union !== undefined ? union.text_en : '', union_name_bn: union !== undefined ? union.text_bn : '' }
      const wardObj = { ward_name: ward !== undefined ? ward.text_en : '', ward_name_bn: ward !== undefined ? ward.text_bn : '' }

      const perdivisionObj = { per_division_name: perdivision !== undefined ? perdivision.text_en : '', per_division_name_bn: perdivision !== undefined ? perdivision.text_bn : '' }
      const perdistrictObj = { per_district_name: perdistrict !== undefined ? perdistrict.text_en : '', per_district_name_bn: perdistrict !== undefined ? perdistrict.text_bn : '' }
      const perupazillaObj = { per_upazilla_name: perupazilla !== undefined ? perupazilla.text_en : '', per_upazilla_name_bn: perupazilla !== undefined ? perupazilla.text_bn : '' }
      const perunionObj = { per_union_name: perunion !== undefined ? perunion.text_en : '', per_union_name_bn: perunion !== undefined ? perunion.text_bn : '' }
      const perwardObj = { per_ward_name: perward !== undefined ? perward.text_en : '', per_ward_name_bn: perward !== undefined ? perward.text_bn : '' }

      return Object.assign(data, divisionObj, districtObj, upazillaObj, unionObj, wardObj, villageObj, blockObj, perdivisionObj, perdistrictObj, perupazillaObj, perunionObj, perwardObj, perVillageObj, perBlockObj)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
